import { SVGProps } from "react";

export const SidebarArrowIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 15.9999C9.49924 15.8683 9.52446 15.7379 9.57423 15.616C9.62399 15.4942 9.69732 15.3834 9.79 15.2899L13.1 11.9999L9.92 8.69994C9.73375 8.51258 9.62921 8.25912 9.62921 7.99494C9.62921 7.73075 9.73375 7.4773 9.92 7.28994C10.013 7.19621 10.1236 7.12182 10.2454 7.07105C10.3673 7.02028 10.498 6.99414 10.63 6.99414C10.762 6.99414 10.8927 7.02028 11.0146 7.07105C11.1364 7.12182 11.247 7.19621 11.34 7.28994L15.2 11.2899C15.3832 11.4769 15.4859 11.7282 15.4859 11.9899C15.4859 12.2517 15.3832 12.503 15.2 12.6899L11.2 16.6899C11.107 16.7837 10.9964 16.8581 10.8746 16.9088C10.7527 16.9596 10.622 16.9857 10.49 16.9857C10.358 16.9857 10.2273 16.9596 10.1054 16.9088C9.98356 16.8581 9.87296 16.7837 9.78 16.6899C9.60132 16.5046 9.50102 16.2574 9.5 15.9999Z"
      fill="white"
    />
  </svg>
);

export const DefaultPage = () => (
  <svg
    fill="#fff"
    height="120px"
    width="120px"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 484.656 484.656"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M451.074,438.034h-9.837V195.806h-0.008c-12.084,0-23.525-3.944-33.045-10.585c-6.411,4.508-13.791,7.84-21.954,9.467
		c-3.746,0.746-7.553,1.118-11.322,1.118c-2.499,0-4.943-0.278-7.371-0.595v180.184c0,10.282-8.339,18.62-18.629,18.62h-66.923
		v-51.032l23.595,39.378c2.785,4.657,7.72,7.236,12.782,7.236c2.595,0,5.237-0.683,7.634-2.119
		c7.045-4.228,9.338-13.362,5.108-20.414l-47.429-79.167c-2.936-4.912-8.268-7.339-13.607-7.037
		c-0.357-0.033-54.953-0.041-55.286-0.017c-5.411-0.372-10.822,2.079-13.805,7.054l-47.43,79.167
		c-4.229,7.052-1.937,16.186,5.109,20.414c2.397,1.436,5.039,2.119,7.634,2.119c5.062,0,9.997-2.579,12.782-7.236l23.746-39.631
		v51.285h-67.066c-10.291,0-18.629-8.337-18.629-18.62V195.211c-2.427,0.317-4.872,0.595-7.371,0.595
		c-3.745,0-7.554-0.372-11.361-1.126c-8.157-1.626-15.52-4.951-21.923-9.459c-9.52,6.641-20.953,10.585-33.038,10.585h-0.008
		v242.228h-9.837c-12.877,0-23.311,10.433-23.311,23.311c0,12.877,10.434,23.311,23.311,23.311h417.492
		c12.878,0,23.311-10.434,23.311-23.311C474.385,448.467,463.952,438.034,451.074,438.034z"
      />
      <path
        d="M242.321,197.226c-17.115,0-31.007,15.877-31.007,35.474c0,19.581,13.892,35.473,31.007,35.473
		c17.13,0,31.015-15.892,31.015-35.473C273.335,213.103,259.451,197.226,242.321,197.226z"
      />
      <path
        d="M33.938,169.553c3.167,0.936,6.356,1.387,9.506,1.387c14.265,0,27.453-9.29,31.744-23.666l1.745-5.832
		c1.563,13.973,11.798,25.968,26.318,28.857c2.181,0.436,4.363,0.642,6.506,0.642c15.495,0,29.34-10.916,32.482-26.682l0.809-4.054
		c1.143,15.78,13.409,28.952,29.689,30.57c1.12,0.11,2.23,0.158,3.333,0.158c16.821,0,31.237-12.757,32.943-29.863l0.246-2.46
		c0.452,17.923,15.044,32.323,33.07,32.323c18.025,0,32.617-14.4,33.069-32.323l0.245,2.46
		c1.707,17.106,16.123,29.863,32.944,29.863c1.103,0,2.213-0.047,3.332-0.158c16.28-1.618,28.555-14.79,29.689-30.57l0.809,4.054
		c3.143,15.773,16.987,26.682,32.481,26.682c2.144,0,4.326-0.206,6.507-0.642c14.52-2.889,24.764-14.884,26.318-28.857l1.745,5.832
		c4.292,14.377,17.479,23.666,31.744,23.666c3.149,0,6.339-0.452,9.506-1.387c17.549-5.246,27.515-23.716,22.279-41.25
		L443.076,28.158C438.086,11.449,422.718,0,405.279,0H79.377C61.938,0,46.57,11.449,41.58,28.158L11.659,128.303
		C6.423,145.837,16.389,164.307,33.938,169.553z"
      />
    </g>
  </svg>
);
export const DefaultImage = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4625 11.6214C11.4625 13.4623 10.0299 14.9546 8.26265 14.9546C6.49541 14.9546 5.06278 13.4623 5.06278 11.6214C5.06278 9.78057 6.49541 8.28825 8.26265 8.28825C10.0299 8.28825 11.4625 9.78057 11.4625 11.6214Z"
      fill="#C2CBD9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.000732422 6.00019C0.000732422 4.15932 1.43337 2.66699 3.20061 2.66699H28.7996C30.5668 2.66699 31.9995 4.15932 31.9995 6.00019V25.9994C31.9995 27.8403 30.5669 29.3326 28.7996 29.3326H26.6874C26.6804 29.3326 26.6734 29.3326 26.6664 29.3326H3.92988C3.92386 29.3326 3.91783 29.3326 3.91181 29.3326H3.20061C1.43337 29.3326 0.000732422 27.8403 0.000732422 25.9994V6.00019ZM3.20061 5.16689H28.7996C29.2414 5.16689 29.5996 5.53997 29.5996 6.00019V16.3866L22.6474 9.33343L10.8198 21.3329L7.33568 17.7982L2.40064 22.805V6.00019C2.40064 5.53997 2.7588 5.16689 3.20061 5.16689Z"
      fill="#C2CBD9"
    />
  </svg>
);
export const DeleteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.187 8H17.812L17.117 19.125C17.0852 19.6328 16.8611 20.1094 16.4902 20.4577C16.1194 20.8061 15.6298 21 15.121 21H8.879C8.37022 21 7.88058 20.8061 7.50975 20.4577C7.13893 20.1094 6.9148 19.6328 6.883 19.125L6.187 8ZM19 5V7H5V5H8V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V5H19ZM10 5H14V4H10V5Z"
      fill="#EB5757"
    />
  </svg>
);
export const HorizontalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={24} height={24} rx={12} fill="#fff" />
    <path
      d="M17.6 16.2H6.4M17.6 12H6.4m11.2-4.2H6.4"
      stroke="#0F6FDF"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
export const VerticalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={24}
      width={24}
      height={24}
      rx={12}
      transform="rotate(90 24 0)"
      fill="#F5F5F5"
    />
    <path
      d="M7.8 17.6V6.4M12 17.6V6.4m4.2 11.2V6.4"
      stroke="#999"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
export const MinusIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_829_20251)">
      <path
        d="M4.16699 9.99988H15.8337"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_829_20251">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const PlusIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_829_20245)">
      <path
        d="M10 4.16655V15.8332"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.16699 9.99988H15.8337"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_829_20245">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const LeftArrowIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33301 5L3.33301 10L8.33301 15"
      stroke="black"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.667 10H3.33366"
      stroke="black"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const SearchIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8651 12.7436C14.8545 11.4675 15.3208 9.8622 15.1691 8.25433C15.0175 6.64647 14.2592 5.1568 13.0486 4.08839C11.8379 3.01998 10.2659 2.45309 8.65235 2.50304C7.03875 2.55299 5.50478 3.21604 4.36252 4.35728C3.21925 5.49932 2.55431 7.03449 2.50318 8.64995C2.45206 10.2654 3.0186 11.8396 4.08736 13.0517C5.15613 14.2638 6.64667 15.0226 8.25525 15.1734C9.86382 15.3243 11.4694 14.8559 12.7447 13.8636L12.7788 13.8994L16.1447 17.2674C16.2184 17.3411 16.306 17.3996 16.4023 17.4395C16.4986 17.4795 16.6018 17.5 16.7061 17.5C16.8103 17.5 16.9136 17.4795 17.0099 17.4395C17.1062 17.3996 17.1937 17.3411 17.2675 17.2674C17.3412 17.1936 17.3997 17.1061 17.4396 17.0097C17.4795 16.9134 17.5 16.8101 17.5 16.7058C17.5 16.6015 17.4795 16.4982 17.4396 16.4018C17.3997 16.3055 17.3412 16.2179 17.2675 16.1442L13.9008 12.7769C13.8892 12.7655 13.8773 12.7544 13.8651 12.7436ZM12.2178 5.48049C12.6658 5.92142 13.0221 6.44672 13.2662 7.02609C13.5102 7.60546 13.6372 8.22744 13.6397 8.85615C13.6423 9.48485 13.5204 10.1078 13.2811 10.6892C13.0418 11.2705 12.6898 11.7987 12.2454 12.2433C11.801 12.6878 11.273 13.04 10.6919 13.2794C10.1108 13.5188 9.48804 13.6408 8.85959 13.6382C8.23113 13.6356 7.6094 13.5086 7.03026 13.2645C6.45112 13.0203 5.92603 12.6639 5.48527 12.2157C4.60446 11.3201 4.11309 10.1126 4.11821 8.85615C4.12332 7.59973 4.6245 6.39623 5.51258 5.5078C6.40065 4.61937 7.60367 4.11799 8.85959 4.11288C10.1155 4.10776 11.3226 4.59932 12.2178 5.48049Z"
      fill="#999999"
    />
  </svg>
);
export const TickIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6113 23.7975C11.0023 23.7975 10.4255 23.5133 10.0559 23.0233L5.0537 16.4139C4.89905 16.2098 4.78614 15.9772 4.72144 15.7294C4.65673 15.4816 4.6415 15.2235 4.6766 14.9698C4.71171 14.7162 4.79646 14.4719 4.92603 14.251C5.0556 14.0301 5.22743 13.8369 5.4317 13.6825C5.6359 13.5274 5.86871 13.4141 6.11676 13.3492C6.36482 13.2842 6.62325 13.2689 6.87726 13.304C7.13126 13.3391 7.37584 13.424 7.59697 13.5538C7.8181 13.6836 8.01144 13.8558 8.1659 14.0605L11.4573 18.4061L19.7327 5.11728C20.0072 4.67852 20.4444 4.36653 20.9486 4.24973C21.4528 4.13294 21.9827 4.22089 22.4221 4.49428C23.3363 5.06268 23.6177 6.26668 23.0465 7.18228L13.2689 22.8763C13.102 23.1454 12.8719 23.3697 12.5987 23.5297C12.3254 23.6898 12.0173 23.7807 11.7009 23.7947C11.6701 23.7975 11.6421 23.7975 11.6113 23.7975Z"
      fill="#999999"
    />
  </svg>
);
export const ClockIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.8337 13.9998C26.8337 21.0874 21.0879 26.8331 14.0003 26.8331C6.91267 26.8331 1.16699 21.0874 1.16699 13.9998C1.16699 6.91214 6.91267 1.16646 14.0003 1.16646C21.0879 1.16646 26.8337 6.91214 26.8337 13.9998ZM3.50829 13.9998C3.50829 19.7944 8.20574 24.4919 14.0003 24.4919C19.7949 24.4919 24.4924 19.7944 24.4924 13.9998C24.4924 8.2052 19.7949 3.50776 14.0003 3.50776C8.20574 3.50776 3.50829 8.2052 3.50829 13.9998Z"
      fill="#999999"
    />
    <path
      d="M13.9997 5.83354C13.3553 5.83354 12.833 6.35587 12.833 7.00021V14.5447C12.833 14.5447 12.833 14.8488 12.9808 15.0776C13.0798 15.2716 13.234 15.4402 13.4366 15.5572L18.8264 18.6691C19.3844 18.9912 20.098 18.8 20.4201 18.242C20.7422 17.684 20.5511 16.9704 19.9931 16.6483L15.1663 13.8616V7.00021C15.1663 6.35588 14.644 5.83354 13.9997 5.83354Z"
      fill="#999999"
    />
  </svg>
);
export const TruckIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4031 5.41406V19.5563H10.1172H9.76719C9.66875 19.5563 9.58125 19.4906 9.55937 19.3922C9.275 17.7734 7.875 16.5594 6.17969 16.5594C4.48438 16.5594 3.09531 17.7734 2.81094 19.3813C2.78906 19.4797 2.7125 19.5453 2.60312 19.5453H0.896875C0.39375 19.5453 0 19.1406 0 18.6484V5.41406H12.4031ZM25.6812 20.0047C25.6812 21.4266 24.5328 22.575 23.1109 22.575C21.6891 22.575 20.5406 21.4266 20.5406 20.0047C20.5406 18.5828 21.6891 17.4344 23.1109 17.4344C24.5328 17.4344 25.6812 18.5828 25.6812 20.0047ZM24.0953 20.0047C24.0953 19.4688 23.6469 19.0203 23.1109 19.0203C22.575 19.0203 22.1266 19.4688 22.1266 20.0047C22.1266 20.5406 22.575 20.9891 23.1109 20.9891C23.6578 20.9891 24.0953 20.5406 24.0953 20.0047ZM8.75 20.0047C8.75 21.4266 7.60156 22.575 6.17969 22.575C4.75781 22.575 3.60938 21.4266 3.60938 20.0047C3.60938 18.5828 4.75781 17.4344 6.17969 17.4344C7.60156 17.4344 8.75 18.5828 8.75 20.0047ZM7.16406 20.0047C7.16406 19.4688 6.71562 19.0203 6.17969 19.0203C5.64375 19.0203 5.19531 19.4688 5.19531 20.0047C5.19531 20.5406 5.64375 20.9891 6.17969 20.9891C6.71562 20.9891 7.16406 20.5406 7.16406 20.0047ZM28 17.9047V18.5938C28 19.1188 27.5844 19.5453 27.0484 19.5453H26.6875C26.5891 19.5453 26.5016 19.4797 26.4797 19.3813C26.1953 17.7734 24.7953 16.5484 23.1109 16.5484C21.4266 16.5484 20.0156 17.7734 19.7422 19.3813C19.7203 19.4797 19.6438 19.5453 19.5344 19.5453H13.2016V8.75H17.0844C17.3578 8.76094 17.6203 8.87031 17.8062 9.05625L21.9625 13.5406C21.9953 13.5734 22.0609 13.6063 22.1156 13.6063H25.7578C26.5891 13.6063 27.2563 14.2734 27.2563 15.0938V17.7188H27.8031C27.9016 17.7078 28 17.8063 28 17.9047ZM20.125 13.2453L17.1172 9.93125C17.0734 9.89844 17.0187 9.86563 16.9531 9.86563H14.5578C14.4484 9.86563 14.35 9.96406 14.35 10.0734V13.3984C14.35 13.5078 14.4484 13.6063 14.5578 13.6063H19.9609C20.1469 13.6063 20.2344 13.3875 20.125 13.2453Z"
      fill="#999999"
    />
  </svg>
);
export const FlagIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1875 4.2C22.925 4.025 22.575 4.025 22.3125 4.2C19.25 6.125 16.975 4.9 14.4375 3.5C11.8125 2.1 8.75 0.525001 4.8125 2.8875C4.55 3.15 4.375 3.4125 4.375 3.7625V15.75V16.0125V25.375C4.375 25.9 4.725 26.25 5.25 26.25C5.775 26.25 6.125 25.9 6.125 25.375V16.5375C9.0125 14.9625 11.1125 16.1 13.5625 17.4125C15.225 18.2875 17.0625 19.25 19.1625 19.25C20.3875 19.25 21.7875 18.9 23.1875 18.025C23.45 17.85 23.625 17.5875 23.625 17.2375V4.9875C23.625 4.6375 23.45 4.375 23.1875 4.2Z"
      fill="#999999"
    />
  </svg>
);
export const ProfileIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_73_12511)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0001 12.4963C9.49214 12.4963 7.72346 10.6903 7.45931 7.95567L7.08483 5.00119C6.85271 2.0985 9.22135 0 12.0001 0C14.7788 0 17.1475 2.0985 16.9152 5.00119L16.5408 7.95567C16.2765 10.6902 14.508 12.4963 12.0001 12.4963ZM21.5339 18.6666L21.7131 19.808C21.777 20.2154 21.6928 20.8134 21.3277 21.1123C21.2809 21.1506 21.2219 21.2081 21.1475 21.2808C20.4457 21.9659 18.3622 24.0001 12.0001 24.0001C5.63793 24.0001 3.55431 21.9659 2.85252 21.2808C2.77806 21.2081 2.71916 21.1506 2.67235 21.1123C2.30738 20.8134 2.22306 20.2154 2.28695 19.808L2.46629 18.6666C2.61643 17.7095 3.21784 16.8834 4.08231 16.4465L7.85443 14.539C7.97251 14.4793 8.06865 14.3893 8.14923 14.2888C9.24123 15.4434 10.5681 16.1226 12.0001 16.1226C13.4321 16.1226 14.7588 15.4434 15.8509 14.2888C15.9315 14.3893 16.0276 14.4793 16.1457 14.539L19.9179 16.4465C20.7822 16.8834 21.3837 17.7095 21.5339 18.6666Z"
        fill="#3E5189"
      />
    </g>
    <defs>
      <clipPath id="clip0_73_12511">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const RightArrowIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.49998 15.9999C9.49922 15.8683 9.52445 15.7379 9.57421 15.616C9.62398 15.4942 9.6973 15.3834 9.78998 15.2899L13.1 11.9999L9.91998 8.69994C9.73373 8.51258 9.62919 8.25912 9.62919 7.99494C9.62919 7.73075 9.73373 7.4773 9.91998 7.28994C10.0129 7.19621 10.1235 7.12182 10.2454 7.07105C10.3673 7.02028 10.498 6.99414 10.63 6.99414C10.762 6.99414 10.8927 7.02028 11.0146 7.07105C11.1364 7.12182 11.247 7.19621 11.34 7.28994L15.2 11.2899C15.3832 11.4769 15.4858 11.7282 15.4858 11.9899C15.4858 12.2517 15.3832 12.503 15.2 12.6899L11.2 16.6899C11.107 16.7837 10.9964 16.8581 10.8746 16.9088C10.7527 16.9596 10.622 16.9857 10.49 16.9857C10.358 16.9857 10.2273 16.9596 10.1054 16.9088C9.98355 16.8581 9.87295 16.7837 9.77998 16.6899C9.6013 16.5046 9.50101 16.2574 9.49998 15.9999Z"
      fill="black"
    />
  </svg>
);
export const MinimumDollarIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 14H10C9.71667 14 9.47917 14.0958 9.2875 14.2875C9.09583 14.4792 9 14.7167 9 15C9 15.2833 9.09583 15.5208 9.2875 15.7125C9.47917 15.9042 9.71667 16 10 16H11C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17C12.2667 17 12.4542 16.8792 12.5625 16.6375C12.6708 16.3958 12.8167 16.1833 13 16H14C14.2833 16 14.5208 15.9042 14.7125 15.7125C14.9042 15.5208 15 15.2833 15 15V12C15 11.7167 14.9042 11.4792 14.7125 11.2875C14.5208 11.0958 14.2833 11 14 11H11V10H14C14.2833 10 14.5208 9.90417 14.7125 9.7125C14.9042 9.52083 15 9.28333 15 9C15 8.71667 14.9042 8.47917 14.7125 8.2875C14.5208 8.09583 14.2833 8 14 8H13C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7333 7 11.5458 7.12083 11.4375 7.3625C11.3292 7.60417 11.1833 7.81667 11 8H10C9.71667 8 9.47917 8.09583 9.2875 8.2875C9.09583 8.47917 9 8.71667 9 9V12C9 12.2833 9.09583 12.5208 9.2875 12.7125C9.47917 12.9042 9.71667 13 10 13H13V14ZM4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4Z"
      fill="#00C537"
    />
  </svg>
);
export const WarningIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.725 21C2.54167 21 2.375 20.9542 2.225 20.8625C2.075 20.7708 1.95834 20.65 1.875 20.5C1.79167 20.35 1.74584 20.1875 1.7375 20.0125C1.72917 19.8375 1.775 19.6667 1.875 19.5L11.125 3.5C11.225 3.33333 11.3542 3.20833 11.5125 3.125C11.6708 3.04167 11.8333 3 12 3C12.1667 3 12.3292 3.04167 12.4875 3.125C12.6458 3.20833 12.775 3.33333 12.875 3.5L22.125 19.5C22.225 19.6667 22.2708 19.8375 22.2625 20.0125C22.2542 20.1875 22.2083 20.35 22.125 20.5C22.0417 20.65 21.925 20.7708 21.775 20.8625C21.625 20.9542 21.4583 21 21.275 21H2.725ZM12 18C12.2833 18 12.5208 17.9042 12.7125 17.7125C12.9042 17.5208 13 17.2833 13 17C13 16.7167 12.9042 16.4792 12.7125 16.2875C12.5208 16.0958 12.2833 16 12 16C11.7167 16 11.4792 16.0958 11.2875 16.2875C11.0958 16.4792 11 16.7167 11 17C11 17.2833 11.0958 17.5208 11.2875 17.7125C11.4792 17.9042 11.7167 18 12 18ZM12 15C12.2833 15 12.5208 14.9042 12.7125 14.7125C12.9042 14.5208 13 14.2833 13 14V11C13 10.7167 12.9042 10.4792 12.7125 10.2875C12.5208 10.0958 12.2833 10 12 10C11.7167 10 11.4792 10.0958 11.2875 10.2875C11.0958 10.4792 11 10.7167 11 11V14C11 14.2833 11.0958 14.5208 11.2875 14.7125C11.4792 14.9042 11.7167 15 12 15Z"
      fill="#FFB345"
    />
  </svg>
);

export const CopyIcon = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.07143 22H17.3571C17.6413 22 17.9138 21.8871 18.1148 21.6862C18.3157 21.4853 18.4286 21.2127 18.4286 20.9286V6.28571C18.4286 6.09627 18.3533 5.91459 18.2194 5.78064C18.0854 5.64668 17.9037 5.57143 17.7143 5.57143H3.07143C2.78727 5.57143 2.51475 5.68431 2.31381 5.88524C2.11288 6.08617 2 6.3587 2 6.64286V20.9286C2 21.2127 2.11288 21.4853 2.31381 21.6862C2.51475 21.8871 2.78727 22 3.07143 22Z"
      fill="black"
    />
    <path
      d="M18.4286 4.14286H5.57143V3.07143C5.57143 2.78727 5.68431 2.51475 5.88524 2.31381C6.08617 2.11288 6.3587 2 6.64286 2H20.75C21.0815 2 21.3995 2.1317 21.6339 2.36612C21.8683 2.60054 22 2.91848 22 3.25V17.3571C22 17.6413 21.8871 17.9138 21.6862 18.1148C21.4853 18.3157 21.2127 18.4286 20.9286 18.4286H19.8571V5.57143C19.8571 5.19255 19.7066 4.82919 19.4387 4.56128C19.1708 4.29337 18.8075 4.14286 18.4286 4.14286Z"
      fill="black"
    />
  </svg>
);

export const ArrowDown = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 7.91669C13.2237 7.91606 13.1149 7.93708 13.0134 7.97855C12.9119 8.02002 12.8195 8.08113 12.7417 8.15836L9.99999 10.9167L7.24999 8.26669C7.09385 8.11149 6.88264 8.02437 6.66249 8.02437C6.44233 8.02437 6.23113 8.11149 6.07499 8.26669C5.99688 8.34416 5.93489 8.43633 5.89258 8.53788C5.85027 8.63943 5.82849 8.74835 5.82849 8.85836C5.82849 8.96837 5.85027 9.07729 5.89258 9.17884C5.93489 9.28039 5.99688 9.37256 6.07499 9.45003L9.40832 12.6667C9.5641 12.8194 9.77353 12.9049 9.99166 12.9049C10.2098 12.9049 10.4192 12.8194 10.575 12.6667L13.9083 9.33336C13.9864 9.25589 14.0484 9.16372 14.0907 9.06217C14.133 8.96062 14.1548 8.8517 14.1548 8.74169C14.1548 8.63168 14.133 8.52276 14.0907 8.42121C14.0484 8.31966 13.9864 8.2275 13.9083 8.15003C13.7538 8.00113 13.5479 7.91755 13.3333 7.91669Z"
      fill="black"
    />
  </svg>
);

import { ISidebarRoute } from "../sidebar.types";

export const sidebarRoutes: ISidebarRoute[] = [
  {
    path: "/order",
    translate: "order",
    role: "order",
  },
  {
    path: "/customer",
    translate: "customer",
    role: "customer",
  },
  {
    path: "/product",
    role: "product",
    translate: "product",
  },
  {
    path: "/category",
    role: "storeProductCategory",
    translate: "category",
  },
  {
    path: "/about",
    role: "store",
    translate: "about",
  },
  {
    path: "/employee",
    role: "employee",
    translate: "employee",
  },
  {
    path: "/role",
    role: "role",
    translate: "role",
  },
  {
    path: "/transaction",
    translate: "transaction",
    role: "transaction",
  },
  {
    path: "/integration",
    role: "integration",
    translate: "integration",
  },
  {
    path: "/settings",
    translate: "settings",
    role: "_id",
  },
];
